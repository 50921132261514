import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { app } from '../../utils/firebase';
import { getStorage, ref, uploadBytes } from 'firebase/storage';

import Button from '../../components/Button';
import Input from '../../components/Input';
import corpo from '../../assets/icons/corpo.jpeg';

import {
  Col01,
  Col02,
  Col03,
  Container,
  Content,
  ImagemContent,
  Imagens,
  Perimetria,
  Riscos,
  Schedule,
  Section,
  Sindrome,
  Title,
  TwoFields,
} from './styles';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import Modal from '../../components/Modal';

import { Aluno } from '../../model/Aluno';
import { FiChevronLeft } from 'react-icons/fi';
import getValidationErrors from '../../utils/getValidationErrors';
import _ from 'lodash';
import FileInput from '../../components/FileInput';

const firebaseUrl = (foto: string) => {
  if (foto) {
    return `https://firebasestorage.googleapis.com/v0/b/atitude-vida-saudavel.appspot.com/o/antropometria%2F${foto}?alt=media`;
  } else {
    return '';
  }
};

const Antropometria: React.FC = () => {
  const { alunoid, id } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const storage = getStorage(app);
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [aluno, setAluno] = useState<Aluno>({} as Aluno);
  const [frente, setFrente] = useState('');
  const [costa, setCosta] = useState('');
  const [perfil, setPerfil] = useState('');

  useEffect(() => {
    formRef.current?.setFieldValue('aluno', Number(alunoid));
  }, [alunoid]);

  useEffect(() => {
    api
      .get('alunos/' + alunoid)
      .then((response) => {
        setAluno(response.data);
      })
      .catch((e) => {
        addToast({
          type: 'error',
          title: 'error',
        });
      });
  }, [id, alunoid, addToast]);

  useEffect(() => {
    if (Number(id) !== 0) {
      api
        .get(`antropometrias/${id}`)
        .then((response) => {
          const data = response.data;
          formRef.current?.setData(response.data);
          handleRCEChange();
          handleRCQchange();
          handleTriglicerideo();
          handleGlicose();
          handlePressaoArterial();
          handleCintura();
          handleHDL();
          sindMet();
          if (data.fotoFrente) {
            setFrente(data.fotoFrente);
          }
          if (data.fotoCosta) {
            setCosta(data.fotoCosta);
          }
          if (data.fotoPerfil) {
            setPerfil(data.fotoPerfil);
          }
        })
        .catch((e) => {
          addToast({
            type: 'error',
            title: 'error',
          });
        });
    }
    // eslint-disable-next-line
  }, [aluno, id]);

  const handleClose = () => {
    setModal(false);
  };

  const handleRCEChange = () => {
    const altura = formRef.current?.getFieldValue('altura') || 0;
    const cintura = formRef.current?.getFieldValue('cintura') || 0;
    let rce: number = (cintura / altura).toFixed(2) as unknown as number;
    let result = '';
    let diff: number = 0;

    if (rce < 0.49) {
      result = 'Normal';
    } else if (rce < 0.60) {
      result = 'Risco aumentado';
    } else if (rce > 0.60) {
      result = 'Risco alto';
    }else{
      result = 'Sem classificação';
    }

    diff = (rce - 0.49).toFixed(2) as unknown as number;
    
    formRef.current?.setFieldValue('PER22', rce);
    formRef.current?.setFieldValue('PER23', result);
    formRef.current?.setFieldValue('PER24', diff);

    if (cintura) {
      handleCinturaChange();
    }
  };

  const handleCinturaChange = () => {
    const cintura = formRef.current?.getFieldValue('cintura') || 0;
    let result = '';
    let diff = 0;

    if (cintura < 80 && aluno.sexo === 2) {
      result = 'Normal';
    } else if (cintura < 88 && aluno.sexo === 2) {
      result = 'Risco aumentado';
    } else if (cintura > 87 && aluno.sexo === 2) {
      result = 'Risco muito elevado';
    } else if (cintura < 94 && aluno.sexo === 1) {
      result = 'Normal';
    } else if (cintura < 102 && aluno.sexo === 1) {
      result = 'Risco aumentado';
    } else if (cintura > 101 && aluno.sexo === 1) {
      result = 'Risco muito elevado';
    }

    if (aluno.sexo === 2) {
      diff = (cintura - 80).toFixed(2) as unknown as number;
    } else {
      diff = (cintura - 94).toFixed(2) as unknown as number;
    }

    formRef.current?.setFieldValue('PER25', cintura);
    formRef.current?.setFieldValue('PER26', result);
    formRef.current?.setFieldValue('PER27', diff);
  };

  const handleRCQchange = () => {
    const cintura = formRef.current?.getFieldValue('cintura') || 0;
    const quadril = formRef.current?.getFieldValue('quadril') || 0;
    const rcq = (cintura / quadril).toFixed(2) as unknown as number;

    let result = '';
    let diff = 0;

    if (rcq < 0.8 && aluno.sexo === 2) {
      result = 'Normal';
    } else if (rcq < 0.87 && aluno.sexo === 2) {
      result = 'Risco aumentado';
    } else if (rcq > 0.86 && aluno.sexo === 2) {
      result = 'Risco muito elevado';
    } else if (rcq < 0.95 && aluno.sexo === 1) {
      result = 'Normal';
    } else if (rcq < 1.01 && aluno.sexo === 1) {
      result = 'Risco aumentado';
    } else if (rcq > 1 && aluno.sexo === 1) {
      result = 'Risco muito elevado';
    }

    if (aluno.sexo === 2) {
      diff = (rcq - 0.8).toFixed(2) as unknown as number;
    } else {
      diff = (rcq - 0.95).toFixed(2) as unknown as number;
    }

    formRef.current?.setFieldValue('PER28', rcq);
    formRef.current?.setFieldValue('PER29', result);
    formRef.current?.setFieldValue('PER30', diff);
  };

  const handleCintura = () => {
    const value = formRef.current?.getFieldValue('cincuferenciaCintura');
    if (value === '') return;
    let result = '';

    if (value < 89 && aluno.sexo === 2) {
      result = 'Baixo risco';
    } else if (value > 88 && aluno.sexo === 2) {
      result = 'Risco elevado';
    } else if (value < 103 && aluno.sexo === 1) {
      result = 'Baixo risco';
    } else if (value > 102 && aluno.sexo === 1) {
      result = 'Risco elevado';
    }

    formRef.current?.setFieldValue('cincuferenciacinturaclassif', result);
    sindMet();
  };

  const handleTriglicerideo = () => {
    const value = formRef.current?.getFieldValue('triglicerideos');
    if (value === '') return;
    let result = '';

    if (value < 150) {
      result = 'Baixo risco';
    } else {
      result = 'Risco elevado';
    }

    formRef.current?.setFieldValue('triglicerideosclassif', result);
    sindMet();
  };

  const handleHDL = async () => {
    const value = formRef.current?.getFieldValue('hdlColesterol');
    if (value === '') return;
    let result = '';

    if (value < 50 && aluno.sexo === 2) {
      result = 'Risco elevado';
    } else if (value >= 50 && aluno.sexo === 2) {
      result = 'Baixo risco';
    } else if (value < 40 && aluno.sexo === 1) {
      result = 'Risco elevado';
    } else if (value >= 40 && aluno.sexo === 1) {
      result = 'Baixo risco';
    }

    formRef.current?.setFieldValue('hdlcolesterolclassif', result);
    sindMet();
  };

  const handlePressaoArterial = () => {
    const value1 = formRef.current?.getFieldValue('pressaoArterialSistolica');
    const value2 = formRef.current?.getFieldValue('pressaoArterialDiastolica');
    if (value1 === '' || value2 === '') return;
    let result = '';

    if (value1 >= 130 || value2 >= 85) {
      result = 'Risco elevado';
    } else {
      result = 'Baixo risco';
    }

    formRef.current?.setFieldValue('pressaoarterialclassif', result);
    sindMet();
  };

  const handleGlicose = () => {
    const value = formRef.current?.getFieldValue('glicose');
    if (value === '') return;
    let result = '';

    if (value < 111) {
      result = 'Baixo risco';
    } else {
      result = 'Risco elevado';
    }

    formRef.current?.setFieldValue('glicoseclassif', result);
    sindMet();
  };

  const sindMet = () => {
    const data: any = formRef.current?.getData();

    const { cincuferenciacinturaclassif, triglicerideosclassif, hdlcolesterolclassif, pressaoarterialclassif, glicoseclassif } = data;
    if (
      cincuferenciacinturaclassif === '' &&
      triglicerideosclassif === '' &&
      hdlcolesterolclassif === '' &&
      pressaoarterialclassif === '' &&
      glicoseclassif === ''
    )
      return;
    const resultados = [];
    resultados.push(cincuferenciacinturaclassif);
    resultados.push(triglicerideosclassif);
    resultados.push(hdlcolesterolclassif);
    resultados.push(pressaoarterialclassif);
    resultados.push(glicoseclassif);
    const total = resultados.filter((r) => r === 'Risco elevado').length;

    if (total >= 3) {
      formRef.current?.setFieldValue('sindromeMetabolica', 'Risco cardiovascular e metabólico');
    } else {
      formRef.current?.setFieldValue('sindromeMetabolica', 'Baixo risco');
    }
  };

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          aluno: Yup.number().required('Aluno obrigatorio'),
          peso: Yup.number()
            // .typeError('Peso deve ser um número')
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          altura: Yup.number()
            // .typeError('Altura deve ser um número')
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          pescoco: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          torax: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          cintura: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          abdomen: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          quadril: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          bracoEstendidoDireito: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          bracoEstendidoEsquerdo: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          bracoFlexionadoDireito: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          bracoFlexionadoEsquerdo: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          antebracoDireito: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          antebracoEsquerdo: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          punhoDireito: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          punhoEsquerdo: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          coxaProximalDireita: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          coxaProximalEsquerda: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          coxaMedialDireita: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          coxaMedialEsquerda: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          coxaDistalDireita: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          coxaDistalEsquerda: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          pernaDireita: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          pernaEsquerda: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          cincuferenciaCintura: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          triglicerideos: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          hdlColesterol: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          pressaoArterialSistolica: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          pressaoArterialDiastolica: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
          glicose: Yup.number()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .nullable(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const _data = _.mapValues(data, (v) => (_.isEmpty(v) ? null : v));

        const file1 = data.frente ? data.frente : null;
        const file2 = data.costa ? data.costa : null;
        const file3 = data.perfil ? data.perfil : null;

        const time = new Date().getTime();

        if (file1) {
          const anexoName1 = time + '_' + file1.name;
          const storageRef1 = ref(storage, 'antropometria/' + anexoName1);
          await uploadBytes(storageRef1, file1).then((snapshot) => {
            console.log('Uploaded a blob or file!');
            _data.fotofrente = anexoName1;
          });
        }
        if (file2) {
          const anexoName2 = time + '_' + file2.name;
          const storageRef2 = ref(storage, 'antropometria/' + anexoName2);
          await uploadBytes(storageRef2, file2).then((snapshot) => {
            console.log('Uploaded a blob or file!');
            _data.fotocosta = anexoName2;
          });
        }
        if (file3) {
          const anexoName3 = time + '_' + file3.name;
          const storageRef3 = ref(storage, 'antropometria/' + anexoName3);

          await uploadBytes(storageRef3, file3).then((snapshot) => {
            console.log('Uploaded a blob or file!');
            _data.fotoperfil = anexoName3;
          });
        }

        setLoading(true);
        if (Number(id) === 0) {
          await api.post('antropometrias', _data);
        } else {
          await api.put('antropometrias/' + id, _data);
        }
        setLoading(false);

        formRef.current?.reset();
        addToast({
          type: 'success',
          title: 'Gravado com sucesso!',
        });
        navigate(`/cliente/${alunoid}/questionarios`);
      } catch (error: any) {
        setLoading(false);
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        error.inner.forEach((er: any) => {
          addToast({
            type: 'error',
            title: er.message,
          });
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Verifique o formulário',
        });
      }
    },
    [alunoid, id, addToast, navigate, storage]
  );

  return (
    <>
      <Container>
        {/* <Header /> */}
        <Content>
          <Schedule>
            <Title>
              <h1>Antropometria</h1>
              <p>{/* <span>Hoje</span> */}</p>

              <Link to={`/cliente/${alunoid}/questionarios`}>
                <FiChevronLeft size={20} />
                <span>Voltar</span>
              </Link>
            </Title>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={{ aluno: alunoid, id: id }}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <Section style={{ width: '450px' }}>
                <TwoFields>
                  <Input name="peso" placeholder="Peso (em KG)" />
                  <Input name="altura" placeholder="Altura(em cm):" onChange={handleRCEChange} />
                </TwoFields>
              </Section>
              <Section>
                <strong>PERIMETRIA</strong>
                <Perimetria>
                  <div>
                    <Col01>
                      <strong>Circunferência</strong>
                    </Col01>
                    <Col02>
                      <strong>Descrição</strong>
                    </Col02>
                    <Col03>
                      <div>
                        <strong>D</strong>
                      </div>
                      <div>
                        <strong>E</strong>
                      </div>
                    </Col03>
                  </div>
                  <div>
                    <Col01>Pescoço</Col01>
                    <Col02>
                      Avaliado sentado, posicionar a fita no ponto de menor circunferência do pescoço, logo acima da proeminência laríngea
                      (pomo de Adão).
                    </Col02>
                    <Col03>
                      <Input name="pescoco" />
                    </Col03>
                  </div>
                  <div>
                    <Col01>Tórax</Col01>
                    <Col02>Coloca-se a fita na altura dos mamilos.</Col02>
                    <Col03>
                      <Input name="torax" />
                    </Col03>
                  </div>
                  <div>
                    <Col01>Cintura</Col01>
                    <Col02>
                      Posicionar a fita na metade da distância entre o último arco costal e a crista ilíaca, cerca de 2 cm da cicatriz
                      umbilical.
                    </Col02>
                    <Col03>
                      <Input name="cintura" onChange={handleRCEChange} />
                    </Col03>
                  </div>
                  <div>
                    <Col01>Abdomen</Col01>
                    <Col02>Posicionar a fita métrica sobre a cicatriz umbilical.</Col02>
                    <Col03>
                      <Input name="abdomen" />
                    </Col03>
                  </div>
                  <div>
                    <Col01>Quadril</Col01>
                    <Col02>Posicionar a fita métrica no ponto de maior circunferência dos glúteos.</Col02>
                    <Col03>
                      <Input name="quadril" onChange={handleRCQchange} />
                    </Col03>
                  </div>
                  <div>
                    <Col01>Braço Estendido</Col01>
                    <Col02>Posicionar a fita no ponto de maior perímetro aparente.</Col02>
                    <Col03>
                      <Input name="bracoEstendidoDireito" />
                      <Input name="bracoEstendidoEsquerdo" />
                    </Col03>
                  </div>
                  <div>
                    <Col01>Braço Flexionado</Col01>
                    <Col02>Posicionar a fita no ponto de maior perímetro aparente.</Col02>
                    <Col03>
                      <Input name="bracoFlexionadoDireito" />
                      <Input name="bracoFlexionadoEsquerdo" />
                    </Col03>
                  </div>
                  <div>
                    <Col01>Antebraço</Col01>
                    <Col02>Posicionar a fita no maior perímetro aparente.</Col02>
                    <Col03>
                      <Input name="antebracoDireito" />
                      <Input name="antebracoEsquerdo" />
                    </Col03>
                  </div>
                  <div>
                    <Col01>Punho</Col01>
                    <Col02>Posicionar a fita sobre os processos estilóides do Rádio e da Ulna.</Col02>
                    <Col03>
                      <Input name="punhoDireito" />
                      <Input name="punhoEsquerdo" />
                    </Col03>
                  </div>
                  <div>
                    <Col01>Coxa Proximal</Col01>
                    <Col02>Posicionar a fita métrica sobre imediatamente abaixo da prega glútea.</Col02>
                    <Col03>
                      <Input name="coxaProximalDireita" />
                      <Input name="coxaProximalEsquerda" />
                    </Col03>
                  </div>
                  <div>
                    <Col01>Coxa Medial</Col01>
                    <Col02>
                      Posicionar a fita no ponto meso-femural. Alguns autores recomendam posicionar a fita na metade da distância entre a
                      linha inguinal e a borda superior da patela.
                    </Col02>
                    <Col03>
                      <Input name="coxaMedialDireita" />
                      <Input name="coxaMedialEsquerda" />
                    </Col03>
                  </div>
                  <div>
                    <Col01>Coxa Distal</Col01>
                    <Col02>Posicionar a fita no ponto 5 cm acima da borda superior da patela.</Col02>
                    <Col03>
                      <Input name="coxaDistalDireita" />
                      <Input name="coxaDistalEsquerda" />
                    </Col03>
                  </div>
                  <div>
                    <Col01>Perna</Col01>
                    <Col02>Posicionar a fita métrica no ponto de maior circunferência da perna.</Col02>
                    <Col03>
                      <Input name="pernaDireita" />
                      <Input name="pernaEsquerda" />
                    </Col03>
                  </div>
                </Perimetria>
              </Section>
              <Section>
                <strong>RISCOS CARDIORRESPIRATÓRIOS E METABÓLICOS</strong>
                <Riscos>
                  <strong>RISCO CINTURA-ESTATURA - RCE</strong>
                  <div>
                    <div>
                      <strong>Valor</strong>
                    </div>
                    <div>
                      <strong>Classificação</strong>
                    </div>
                    <div>
                      <strong>Diferença</strong>
                    </div>
                  </div>
                  <div>
                    <div>
                      <Input name="PER22" readOnly />
                    </div>
                    <div>
                      <Input name="PER23" readOnly />
                    </div>
                    <div>
                      <Input name="PER24" readOnly />
                    </div>
                  </div>
                  <strong>CIRCUNFERÊNCIA CINTURA</strong>
                  <div>
                    <div>
                      <strong>Valor</strong>
                    </div>
                    <div>
                      <strong>Classificação</strong>
                    </div>
                    <div>
                      <strong>Diferença</strong>
                    </div>
                  </div>
                  <div>
                    <div>
                      <Input name="PER25" readOnly />
                    </div>
                    <div>
                      <Input name="PER26" readOnly />
                    </div>
                    <div>
                      <Input name="PER27" readOnly />
                    </div>
                  </div>
                  <strong>RISCO CINTURA QUADRIL - RCQ</strong>
                  <div>
                    <div>
                      <strong>Valor</strong>
                    </div>
                    <div>
                      <strong>Classificação</strong>
                    </div>
                    <div>
                      <strong>Diferença</strong>
                    </div>
                  </div>
                  <div>
                    <div>
                      <Input name="PER28" readOnly />
                    </div>
                    <div>
                      <Input name="PER29" readOnly />
                    </div>
                    <div>
                      <Input name="PER30" readOnly />
                    </div>
                  </div>
                </Riscos>
              </Section>
              <Section>
                <strong>SÍNDROME METABÓLICA – RISCO CARDIOVASCULAR E METABÓLICO</strong>
                <Sindrome>
                  <div>
                    <div>
                      <strong>Medidas</strong>
                    </div>
                    <div>
                      <strong>Informação</strong>
                    </div>
                    <div>
                      <strong>Resultado</strong>
                    </div>
                  </div>
                  <div>
                    <div>
                      <strong>Circunferência Cintura</strong>
                    </div>
                    <div>
                      <Input name="cincuferenciaCintura" onChange={handleCintura} />
                    </div>
                    <div>
                      <Input name="cincuferenciacinturaclassif" readOnly />
                    </div>
                  </div>
                  <div>
                    <div>
                      <strong>Triglicerídeos(mg/dL)</strong>
                    </div>
                    <div>
                      <Input name="triglicerideos" onChange={handleTriglicerideo} />
                    </div>
                    <div>
                      <Input name="triglicerideosclassif" readOnly />
                    </div>
                  </div>
                  <div>
                    <div>
                      <strong>HDL Colesterol(mg/dL)</strong>
                    </div>
                    <div>
                      <Input name="hdlColesterol" onChange={handleHDL} />
                    </div>
                    <div>
                      <Input name="hdlcolesterolclassif" readOnly />
                    </div>
                  </div>
                  <div>
                    <div>
                      <strong>Pressão Arterial (mmHg)</strong>
                    </div>
                    <TwoFields>
                      <Input name="pressaoArterialSistolica" onChange={handlePressaoArterial} />
                      <Input name="pressaoArterialDiastolica" onChange={handlePressaoArterial} />
                    </TwoFields>
                    <div>
                      <Input name="pressaoarterialclassif" readOnly />
                    </div>
                  </div>
                  <div>
                    <div>
                      <strong>Glicose(mg/dL)</strong>
                    </div>
                    <div>
                      <Input name="glicose" onChange={handleGlicose} />
                    </div>
                    <div>
                      <Input name="glicoseclassif" readOnly />
                    </div>
                  </div>
                  <div>
                    <div>
                      <strong>Síndrome Metabólica</strong>
                    </div>
                    <div style={{ width: '100%' }}>
                      <Input name="sindromeMetabolica" readOnly />
                    </div>
                  </div>
                </Sindrome>
              </Section>
              <Imagens>
                <FileInput name="frente" placeholder="Inserir Foto Frente" filepath={firebaseUrl(frente)} />
                <FileInput name="costa" placeholder="Inserir Foto Costa" filepath={firebaseUrl(costa)} />
                <FileInput name="perfil" placeholder="Inserir Foto Perfil" filepath={firebaseUrl(perfil)} />
              </Imagens>
              <Button loading={loading} disabled={loading} type="submit">
                Gravar
              </Button>
              <div style={{ visibility: 'hidden' }}>
                <Input name="aluno" type="number" />
                <Input name="id" type="number" />
              </div>
            </Form>
          </Schedule>
          <ImagemContent>
            <img src={corpo} alt="Corpo" />
          </ImagemContent>
        </Content>
      </Container>
      <Modal visible={modal} close={handleClose}>
        <p>Some text in the Modal..</p>
      </Modal>
    </>
  );
};

export default Antropometria;
